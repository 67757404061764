import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g   transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 6400 l0 -6400 6400 0 6400 0 0 6400 0 6400 -6400 0 -6400 0 0
-6400z m8024 2806 c435 -70 789 -243 1061 -520 232 -236 360 -523 404 -911 6
-56 12 -797 16 -1982 5 -1561 4 -1893 -7 -1893 -7 0 -156 -2 -330 -3 -257 -2
-319 1 -323 11 -3 8 -5 838 -5 1845 0 1139 -4 1868 -10 1927 -40 383 -175 628
-440 803 -206 135 -439 197 -742 197 -388 -1 -666 -97 -892 -309 -165 -156
-265 -382 -296 -671 -6 -48 -10 -890 -10 -1947 l0 -1863 -968 0 c-788 0 -990
3 -1092 15 -604 71 -1007 294 -1199 665 -105 201 -156 448 -148 720 4 137 9
182 31 265 82 311 296 567 619 739 59 32 107 61 107 64 0 4 -37 20 -83 35
-408 140 -685 457 -784 897 -19 86 -27 155 -31 274 -20 639 248 1102 788 1359
117 55 284 111 410 136 221 45 255 46 1325 46 l1020 0 3 -107 c1 -63 7 -108
13 -108 8 0 86 47 99 60 12 13 154 82 240 118 177 73 349 120 545 147 133 19
539 13 679 -9z m1686 -5441 l0 -85 -3195 0 -3195 0 0 85 0 85 3195 0 3195 0 0
-85z m-5725 -129 c19 -8 44 -30 55 -48 18 -30 20 -50 20 -231 0 -220 -7 -255
-59 -281 -38 -20 -104 -20 -142 0 -52 26 -59 61 -59 281 0 181 2 201 20 231
34 55 100 75 165 48z m1548 -8 c10 -5 27 -24 37 -41 18 -29 20 -52 20 -235 0
-224 -5 -249 -60 -277 -39 -20 -105 -19 -141 2 -55 33 -60 61 -57 288 3 188 5
206 24 232 36 48 118 63 177 31z m-2059 -15 c3 -16 15 -95 26 -178 34 -249 38
-250 69 -20 12 88 25 175 28 193 l5 33 57 -3 56 -3 3 -282 2 -283 -40 0 -40 0
0 202 c0 117 -4 198 -9 192 -5 -5 -21 -96 -37 -201 l-27 -193 -33 0 c-31 0
-32 1 -43 63 -10 59 -51 333 -51 343 0 2 -4 4 -10 4 -6 0 -10 -75 -10 -206 l0
-205 -32 3 -33 3 -3 269 c-1 148 0 275 2 283 4 9 24 13 60 13 51 0 55 -2 60
-27z m829 16 c58 -16 79 -56 75 -146 -3 -59 -7 -76 -27 -97 l-24 -26 24 -26
c22 -23 24 -36 29 -145 l5 -119 -36 0 c-45 0 -49 10 -49 132 0 82 -2 89 -24
104 -14 8 -35 14 -48 12 -22 -3 -23 -6 -26 -125 l-3 -123 -34 0 -35 0 0 285 0
285 68 0 c37 0 84 -5 105 -11z m387 -24 l0 -35 -67 0 c-38 0 -74 -4 -81 -9 -9
-5 -12 -29 -10 -82 l3 -74 63 -3 62 -3 0 -34 0 -34 -62 -3 -63 -3 0 -90 0 -90
78 -3 77 -3 0 -34 0 -35 -115 0 -114 0 -3 268 c-2 148 -2 276 0 285 3 15 19
17 118 17 l114 0 0 -35z m325 13 c2 -13 11 -72 20 -133 25 -182 36 -245 44
-245 4 0 17 75 30 168 35 261 23 232 92 232 l59 0 0 -285 0 -285 -35 0 -35 0
-1 68 c-1 37 -2 126 -3 199 -1 125 -9 173 -20 122 -6 -25 -56 -370 -56 -382 0
-4 -16 -7 -35 -7 -19 0 -35 2 -35 3 0 2 -13 92 -30 200 -16 108 -30 199 -30
203 0 3 -4 2 -10 -1 -6 -4 -10 -83 -10 -206 l0 -199 -30 0 -30 0 0 285 0 285
55 0 c48 0 55 -3 60 -22z m773 -55 c12 -43 31 -117 43 -165 12 -49 25 -88 30
-88 5 0 9 74 9 165 l0 165 35 0 35 0 0 -285 0 -285 -42 0 -43 0 -49 193 c-46
182 -65 236 -67 192 0 -11 -2 -99 -4 -195 -2 -96 -4 -178 -4 -182 -1 -5 -14
-8 -31 -8 l-30 0 0 285 0 285 49 0 49 0 20 -77z m462 42 l0 -35 -69 0 c-43 0
-72 -5 -78 -12 -4 -7 -8 -44 -8 -83 l1 -70 62 -3 62 -3 0 -34 0 -35 -54 0
c-31 0 -58 -5 -62 -12 -4 -6 -7 -48 -7 -92 l0 -81 75 -3 c80 -3 95 -13 83 -53
-6 -17 -18 -19 -121 -19 l-114 0 0 285 0 285 115 0 115 0 0 -35z m155 -65 c28
-103 47 -126 59 -70 3 17 15 62 27 100 21 69 22 70 55 70 21 0 34 -5 34 -12 0
-7 -23 -82 -50 -165 -49 -149 -50 -156 -50 -273 l0 -120 -40 0 -40 0 0 121 c0
118 -1 124 -50 273 -27 84 -50 158 -50 164 0 7 16 12 39 12 l39 0 27 -100z
m660 85 c44 -21 60 -56 60 -131 0 -50 -5 -69 -23 -94 l-24 -32 20 -21 c44 -47
55 -134 27 -205 -23 -57 -56 -72 -164 -72 l-91 0 0 285 0 285 81 0 c52 0 93
-5 114 -15z m359 -1 c4 -9 4 -25 1 -35 -6 -16 -17 -19 -81 -19 l-74 0 0 -85 0
-85 60 0 60 0 0 -35 0 -35 -60 0 -60 0 0 -95 0 -95 75 0 c41 0 76 -3 77 -7 0
-5 2 -20 3 -35 l2 -28 -118 0 -119 0 0 285 0 285 114 0 c96 0 115 -3 120 -16z
m314 -21 c3 -31 3 -32 -45 -35 l-48 -3 -3 -246 c-2 -246 -2 -247 -25 -251 -58
-10 -56 -19 -59 250 l-3 247 -47 3 c-47 3 -48 3 -48 38 l0 35 138 -3 137 -3 3
-32z m310 0 c3 -31 3 -32 -45 -35 l-48 -3 -3 -247 -2 -248 -40 0 -40 0 -2 248
-3 247 -47 3 c-47 3 -48 3 -48 38 l0 35 138 -3 137 -3 3 -32z m300 0 l3 -32
-78 -3 -78 -3 -3 -82 -3 -83 66 0 65 0 0 -35 0 -34 -62 -3 -63 -3 -3 -92 -3
-92 78 -3 78 -3 3 -32 3 -33 -120 0 -121 0 0 285 0 286 118 -3 117 -3 3 -32z
m274 13 c61 -40 65 -180 7 -242 -18 -19 -18 -22 -4 -28 30 -11 45 -70 45 -176
l0 -100 -39 0 -38 0 -6 111 c-3 62 -10 116 -15 121 -6 6 -25 11 -43 11 l-34 2
-3 -122 -3 -123 -39 0 -40 0 0 286 0 286 93 -4 c67 -3 100 -9 119 -22z m328
-226 l0 -249 73 -3 72 -3 3 -32 3 -33 -115 0 -116 0 0 285 0 285 40 0 40 0 0
-250z m278 -37 l2 -283 -40 0 -40 0 0 286 0 285 38 -3 37 -3 3 -282z m312 252
l0 -34 -72 -3 -73 -3 -3 -87 -3 -88 61 0 60 0 0 -35 0 -34 -57 -3 -58 -3 -3
-122 -3 -123 -39 0 -40 0 0 285 0 285 115 0 115 0 0 -35z m278 -2 l3 -32 -78
-3 -78 -3 -3 -82 -3 -83 59 0 c49 0 60 -3 64 -17 10 -42 -1 -52 -60 -55 l-57
-3 -3 -92 -3 -93 81 0 80 0 0 -35 0 -35 -120 0 -120 0 0 285 0 286 118 -3 117
-3 3 -32z m-3178 -502 c0 -51 -34 -131 -56 -131 -11 0 -14 5 -9 18 16 41 17
72 3 77 -9 4 -13 17 -10 40 4 32 7 35 38 35 33 0 34 -1 34 -39z"/>
<path d="M4415 8543 c-147 -19 -307 -72 -420 -140 -278 -166 -415 -429 -415
-797 0 -433 179 -740 505 -863 203 -77 269 -83 1037 -83 l638 0 5 23 c3 12 4
436 3 942 l-3 920 -660 1 c-363 1 -673 -1 -690 -3z m111 -309 c70 -35 131 -97
201 -205 66 -101 89 -115 129 -78 36 34 113 140 169 232 26 43 49 76 52 73 3
-2 -7 -25 -22 -50 -19 -33 -25 -54 -21 -72 7 -26 -1 -42 -128 -275 -20 -37
-36 -80 -36 -95 0 -40 71 -274 83 -274 5 0 16 8 23 18 12 15 13 11 14 -30 0
-62 25 -181 50 -240 26 -60 25 -68 -4 -68 -26 0 -139 80 -161 114 -8 11 -30
76 -49 143 -36 126 -51 163 -66 163 -5 0 -49 -51 -98 -112 -145 -183 -244
-264 -291 -238 -36 19 -72 71 -65 94 4 11 47 59 95 104 49 46 89 90 89 98 0 8
8 17 18 21 26 8 172 165 172 185 0 9 5 20 11 24 37 22 -81 322 -141 357 -37
22 -108 22 -141 -1 -48 -31 -108 -91 -124 -122 -9 -17 -29 -36 -44 -41 -16 -6
-49 -37 -77 -75 -39 -52 -50 -61 -52 -45 -4 26 -7 26 -32 1 -25 -25 -25 -30 1
-30 27 0 20 -21 -12 -37 -13 -7 -28 -18 -34 -26 -5 -7 -18 -16 -28 -20 -15 -5
-14 0 7 41 13 26 32 74 41 107 10 32 39 104 66 160 93 191 121 221 224 234 62
8 114 -2 181 -35z m1029 4 c-2 -13 -27 -156 -55 -318 -57 -327 -134 -771 -151
-867 l-11 -63 -74 0 c-50 0 -74 4 -74 12 0 6 20 124 44 262 25 138 72 411 106
606 33 195 63 363 66 373 4 14 18 17 80 17 72 0 74 -1 69 -22z"/>
<path d="M4617 6109 c-190 -13 -316 -45 -452 -111 -129 -63 -224 -142 -304
-251 -54 -74 -84 -139 -113 -247 -32 -122 -32 -357 0 -480 39 -149 122 -290
209 -356 21 -16 46 -36 56 -45 25 -23 149 -83 222 -108 179 -60 326 -71 986
-71 480 0 537 2 543 16 3 9 6 381 6 829 0 658 -2 815 -13 823 -15 13 -966 14
-1140 1z m542 -405 c82 -24 131 -53 192 -114 66 -66 103 -133 130 -238 23 -92
24 -141 3 -235 -56 -245 -265 -407 -479 -370 -211 36 -364 213 -382 442 -7
100 8 177 56 276 93 191 298 293 480 239z"/>
<path d="M3910 3567 l-25 -13 -3 -198 c-3 -215 0 -226 49 -226 45 0 50 21 47
231 -3 179 -4 192 -23 205 -16 11 -25 11 -45 1z"/>
<path d="M5440 3567 l-25 -13 -3 -192 c-3 -211 1 -232 47 -232 49 0 52 11 49
225 -3 185 -4 198 -23 211 -16 11 -25 11 -45 1z"/>
<path d="M4212 3562 c-9 -6 -12 -31 -10 -88 l3 -79 31 -3 c47 -5 64 18 64 87
0 35 -5 63 -13 69 -18 16 -60 23 -75 14z"/>
<path d="M6950 3484 l0 -85 37 3 c49 4 68 39 58 104 -7 46 -28 64 -76 64 -17
0 -19 -8 -19 -86z"/>
<path d="M6957 3323 c-4 -3 -7 -46 -7 -95 l0 -88 39 0 c21 0 43 6 49 13 34 41
20 160 -21 171 -29 8 -52 8 -60 -1z"/>
<path d="M8497 3563 c-4 -3 -7 -44 -7 -90 l0 -83 33 0 c48 0 57 15 57 92 0 64
-2 69 -26 78 -30 12 -48 13 -57 3z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
